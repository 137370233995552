import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { updateConfig } from '../store/list/actions';
import { Store } from '@ngrx/store';
import { getErrors } from '../store/errors/selectors';

@Component({
  template: `

        <div class="modal-header">
            <h4 class="modal-title">Configurazione</h4>
        </div>
        <div class="modal-body">

            <p>
              <strong>ID Utente</strong>: {{ record?.id }}
            </p>

            <form [formGroup]="form" action="">

                <div *ngIf="errors && errors.message" class="alert alert-danger">{{ errors.message }}</div>

              <h3 class="mb-4">WhatsApp</h3>

              <ul>
                <li><a href="https://docs.google.com/document/d/1mLZIzcAylig-Taz-H-XsSOY70i6zBwLNuQxbDkKCygo/edit#heading=h.w6sqjn5nub8i" target="_blank">Procedura configurazione</a></li>
                <li><a href="https://www.lastpass.com/it/features/password-generator" target="_blank">Genera webhook token (18 caratteri)</a></li>
              </ul>
              <p></p>

              <div class="form-group row">
                    <label class="col-form-label">N° di telefono</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="wa_number"
                               name="wa_number"
                               [ngClass]="{'is-invalid': errors?.wa_number }">
                        <span *ngIf="errors?.wa_number" class="invalid-feedback" role="alert">{{ errors?.wa_number[0] }}</span>

                    </div>
                </div>

              <div class="form-group row">
                    <label class="col-form-label">App ID</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="wa_app_id"
                               name="wa_app_id"
                               [ngClass]="{'is-invalid': errors?.wa_app_id }">
                        <span *ngIf="errors?.wa_app_id" class="invalid-feedback" role="alert">{{ errors?.wa_app_id[0] }}</span>

                    </div>
                </div>

              <div class="form-group row">
                    <label class="col-form-label">Webhook token</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="wa_webhook_token"
                               name="wa_webhook_token"
                               [ngClass]="{'is-invalid': errors?.wa_webhook_token }">
                        <span *ngIf="errors?.wa_webhook_token" class="invalid-feedback" role="alert">{{ errors?.wa_webhook_token[0] }}</span>

                        <div style="word-break: break-word;" class="mt-1">https://api.mytricosystem.it/api/whatsapp/webhook/{{ record?.id }}?hub.mode=subscribe&hub.verify_token={{ this.form.value.wa_webhook_token }}&hub.challenge=ok</div>

                    </div>
                </div>

              <div class="form-group row">
                    <label class="col-form-label">Access token</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="wa_access_token"
                               name="wa_access_token"
                               [ngClass]="{'is-invalid': errors?.wa_access_token }">
                        <span *ngIf="errors?.wa_access_token" class="invalid-feedback" role="alert">{{ errors?.wa_access_token[0] }}</span>
                    </div>
                </div>

              <div class="form-group row">
                    <label class="col-form-label">Refresh token</label>
                    <div class="control-input">
                        <input class="form-control" type="text" formControlName="wa_refresh_token"
                               name="wa_refresh_token"
                               [ngClass]="{'is-invalid': errors?.wa_refresh_token }">
                        <span *ngIf="errors?.wa_refresh_token" class="invalid-feedback" role="alert">{{ errors?.wa_refresh_token[0] }}</span>
                    </div>
                </div>

            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
            <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitHandler($event)">Salva</button>
        </div>
    `
})

export class ConfigModalComponent implements OnInit {
  public record = null;
  form: FormGroup;
  errors = null;

  constructor(
    fb: FormBuilder,
    private store: Store,
    public modal: NgbActiveModal
  ) {

    this.form = fb.group({
      wa_app_id: fb.control(null, []),
      wa_number: fb.control(null, []),
      wa_webhook_token: fb.control(null, []),
      wa_access_token: fb.control(null, []),
      wa_refresh_token: fb.control(null, []),
    })

    this.store.select(getErrors).subscribe((errors) => this.errors = errors);

  }

  ngOnInit(): void {

    this.form.patchValue({
      id: this.record.id,
      wa_app_id: this.record.wa_config ? this.record.wa_config.app_id : null,
      wa_number: this.record.wa_config ? this.record.wa_config.number : null,
      wa_webhook_token: this.record.wa_config ? this.record.wa_config.webhook_token : null,
      wa_access_token: this.record.wa_config ? this.record.wa_config.access_token : null,
      wa_refresh_token: this.record.wa_config ? this.record.wa_config.refresh_token : null,
    })

    //   this.form.patchValue(this.record);
    // if (this.record) {
    //   this.form.patchValue(this.record);
    //   this.record.permissions.map(permission => {
    //     this.formPermissions.push(new FormControl(permission.name));
    //   })
    // }
  }

  submitHandler(e) {
    // const value = {
    //   ...this.form.value,
    //   id: this.record ? this.record.id : null
    // }

    const value = {
      id: this.record.id,
      wa_config: {
        app_id: this.form.value.wa_app_id,
        number: this.form.value.wa_number,
        webhook_token: this.form.value.wa_webhook_token,
        access_token: this.form.value.wa_access_token,
        refresh_token: this.form.value.wa_refresh_token,
      }
    }

    this.store.dispatch(updateConfig({record: value}))
  }


}
