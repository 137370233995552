
    <div class="DateSelectContainer" [class.show-overlay]="showOverlay">

      <div class="DateSelectContainer_MonthName" [class.template-datepicker]="titleTemplate === 'datepicker'"
           [class.template-agenda]="titleTemplate === 'agenda'" #toggler (click)="showOverlay = true">
        {{ viewDate$ | async | date: formatShow }} <i *ngIf="titleTemplate === 'agenda'"
                                                      class="mdi mdi-chevron-down"></i>
      </div>

      <div class="DateSelectContainer_Inner" #overlay>
        <div class="DateSelectContainer_Nav">
          <button class="btn btn-clean" (click)="handlePrevMonth()"><i class="mdi mdi-chevron-left"></i> Mese precedente</button>
          <button class="btn btn-clean" (click)="handleNextMonth()">Mese seguente <i class="mdi mdi-chevron-right"></i> </button>
        </div>
        <div class="DateSelectContainer_Calendar" #calendarWrapper>

          <mwl-calendar-month-view (dayClicked)="handleDayClick($event)" [headerTemplate]=""
                                   [viewDate]="viewDate$ | async" [weekStartsOn]="1"
                                   [cellTemplate]="customCellTemplate"></mwl-calendar-month-view>

          <ng-template
            #customCellTemplate
            let-day="day"
            let-openDay="openDay"
            let-locale="locale"
            let-viewDate="viewDate"
            let-tooltipPlacement="tooltipPlacement"
            let-highlightDay="highlightDay"
            let-unhighlightDay="unhighlightDay"
            let-eventClicked="eventClicked"
            let-tooltipTemplate="tooltipTemplate"
            let-tooltipAppendToBody="tooltipAppendToBody"
            let-tooltipDelay="tooltipDelay"
            let-trackByEventId="trackByEventId"
            let-validateDrag="validateDrag"
          >
            <div
              class="cal-cell-top"
              [attr.aria-label]="
                { day: day, locale: locale } | calendarA11y : 'monthCell'
              "
            >
              <span aria-hidden="true">
                <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
                  day.badgeTotal
                  }}</span>
                <span class="cal-day-number" [class.selected]="isSelected(day.date)">
                  {{ day.date | calendarDate : 'monthViewDayNumber' : locale }}
                </span>
              </span>
            </div>
            <div class="cal-events" *ngIf="day.events.length > 0">
              <div
                class="cal-event"
                *ngFor="let event of day.events; trackBy: trackByEventId"
                [ngStyle]="{ backgroundColor: event.color?.primary }"
                [ngClass]="event?.cssClass"
                (mouseenter)="highlightDay.emit({ event: event })"
                (mouseleave)="unhighlightDay.emit({ event: event })"
                [mwlCalendarTooltip]="
                  event.title | calendarEventTitle : 'monthTooltip' : event
                "
                [tooltipPlacement]="tooltipPlacement"
                [tooltipEvent]="event"
                [tooltipTemplate]="tooltipTemplate"
                [tooltipAppendToBody]="tooltipAppendToBody"
                [tooltipDelay]="tooltipDelay"
                mwlDraggable
                [class.cal-draggable]="event.draggable"
                dragActiveClass="cal-drag-active"
                [dropData]="{ event: event, draggedFrom: day }"
                [dragAxis]="{ x: event.draggable, y: event.draggable }"
                [validateDrag]="validateDrag"
                [touchStartLongPress]="{ delay: 300, delta: 30 }"
                (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
                [attr.aria-hidden]="{} | calendarA11y : 'hideMonthCellEvents'"
              ></div>
            </div>
          </ng-template>

        </div>

        <div class="DateSelectContainer_Months">
          <div class="DateSelectContainer_MonthsYear">{{ this.currentYear }}</div>
          <ng-container *ngFor="let month of months">
            <div class="DateSelectContainer_MonthsYear" *ngIf="month.index === 1">{{ this.currentYear + 1 }}</div>
            <div class="DateSelectContainer_MonthsItem" (click)="handleMonthClick(month.number)"
                 [class.active]="month.number === this.currentMonthNumber">{{ month.name }}</div>
          </ng-container>
        </div>
      </div>

    </div>
  