import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Moment } from 'moment';
import { DatePickerComponent, IDatePickerConfig } from 'ng2-date-picker';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-datetime-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true
    }
  ],
  styles: [`
    .DateTimePicker {
      display: flex;
      border: 1px solid rgb(170, 170, 170);
      border-radius: 0.42rem;
      padding: 0 5px;
    }

    .DateTimePicker_Date {
      position: relative;
      flex: 1;
    }

    .DateTimePicker_Date::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      width: 20px;
      height: 20px;
      background: url(/assets/icon-calendar.png) center no-repeat;
      background-size: auto 20px;
      margin-top: -10px;
    }

    .DateTimePicker_Time {
      display: flex;
      padding-left: 10px;
      border-left: 1px solid rgb(170, 170, 170);
    }

    .DateTimePicker select {
      padding: 5px;
      outline: 0;
      border: none;
    }

    .DateTimePicker .date-input {
      display: none;
    }


  `],
  template: `
    <div class="DateTimePicker" [ngClass]="{'no-time': !this.time}" [formGroup]="form">

      <input type="text" formControlName="day" class="date-input">

      <div class="DateTimePicker_Date">
        <app-google-date-picker [date]="date" (onDayClick)="handleDayClick($event)" titleTemplate="datepicker"
                                formatShow="dd/MM/yyyy"></app-google-date-picker>
      </div>
      <div *ngIf="time" class="DateTimePicker_Time">
        <select name="hour" formControlName="hour">
          <option [ngValue]="null">Ora</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
        </select>
        <select name="minute" formControlName="minute">
          <option [ngValue]="null">Min</option>
          <option value="00">00</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
      </div>
    </div>
  `
})

export class DatetimePickerComponent implements ControlValueAccessor, OnInit {
  @ViewChild('dayPicker', {static: true}) datePicker: DatePickerComponent;
  @Input() time = true;
  @Input() returns = 'string';
  @Input() hideInput = false;
  @Output() onDateChange = new EventEmitter();

  date$ = new Subject();
  form: FormGroup;

  date: Date = null;
  hour = '08';
  minute = '00';
  // datePickerConfig: any;
  // showDatepicker:boolean = false;

  festivita = [
    '01-01',
    '01-06',
    '04-25',
    '05-01',
    '06-02',
    '08-15',
    '11-01',
    '12-08',
    '12-25',
    '12-26',
  ];

  constructor(fb: FormBuilder) {
    this.form = fb.group({
      day: fb.control(null),
      hour: fb.control('08'),
      minute: fb.control('00'),
    });

    this.isDayDisabledCallback = this.isDayDisabledCallback.bind(this);

    // this.datePickerConfig = {
    //     format: 'DD/MM/YYYY',
    //     locale: 'it',
    //     firstDayOfWeek: 'mo',
    //     monthFormat: 'MMMM, YYYY',
    //     dayBtnCssClassCallback: this.isDayDisabledCallback
    // };

  }

  _onChange = (value: string) => {
  };

  ngOnInit(): void {

    this.form.valueChanges
      .subscribe(({day, hour, minute}) => {
        if (!this.time) {
          hour = 0;
          minute = 0;
        }

        const date = moment(`${day} ${hour}:${minute}`, 'YYYY-MM-DD HH:mm');

        if (this.returns === 'date') {
          // @ts-ignore
          this._onChange(date.toDate());
        } else if (this.returns === 'moment') {
          // @ts-ignore
          this._onChange(date);
        } else if(this.returns === 'string') {
          let stringDate = day;
          if (this.time) {
            stringDate += ` ${hour}:${minute}:00`
          }
          this._onChange(stringDate);
        }

      });

  }

  writeValue(value): void {

    let date = null;

    if (value instanceof moment) {
      date = value;
      this.form.controls['day'].setValue(date.format('YYYY-MM-DD'));
      if (this.time) {
        this.form.controls['hour'].setValue(date.format('HH'));
        this.form.controls['minute'].setValue(date.format('mm'));
      }
    } else if (value instanceof Date) {
      date = moment(value);
      this.form.controls['day'].setValue(date.format('YYYY-MM-DD'));
      if (this.time) {
        this.form.controls['hour'].setValue(date.format('HH'));
        this.form.controls['minute'].setValue(date.format('mm'));
      }
    } else if (value && typeof value.trim() === 'string') {
      date = moment(value, `YYYY-MM-DD${this.time ? ' HH:mm' : ''}`);
      this.form.controls['day'].setValue(date.format('YYYY-MM-DD'));
      if (this.time) {
        this.form.controls['hour'].setValue(date.format('HH'));
        this.form.controls['minute'].setValue(date.format('mm'));
      }
    }

    if (date) {
      this.date = date;
    }

    if (date && this.datePicker) {
      setTimeout(() => {
        this.datePicker.api.moveCalendarTo(date.format('DD/MM/YYYY'));
        this.form.patchValue({}, {emitEvent: true});
      }, 100);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  // tslint:disable-next-line:no-shadowed-variable
  isDayDisabledCallback(moment: any) {
    return this.festivita.indexOf(moment.format('MM-DD')) !== -1 || [7].indexOf(parseInt(moment.format('E'))) !== -1 ? 'dp-calendar-festivo' : '';
  }


  handleDayClick(date: Date) {

    this.form.patchValue({
      day: formatDate(date, 'yyyy-MM-dd', 'it_IT')
    })


  }
}
