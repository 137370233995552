import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { CalendarMonthViewHeaderComponentFormatter } from './CalendarMonthViewHeaderComponentFormatter';
import { CalendarDateFormatter } from 'angular-calendar';
import { CalendarEvent, MonthViewDay } from 'calendar-utils';
import { formatCurrency, formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { addMonths } from 'date-fns';
import VanillaSwipe from 'vanilla-swipe';
import * as moment from 'moment';

@Component({
  selector: 'app-google-date-picker',
  styles: [`

  `],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CalendarMonthViewHeaderComponentFormatter
  }],
  template: `
    <div class="DateSelectContainer" [class.show-overlay]="showOverlay">

      <div class="DateSelectContainer_MonthName" [class.template-datepicker]="titleTemplate === 'datepicker'"
           [class.template-agenda]="titleTemplate === 'agenda'" #toggler (click)="showOverlay = true">
        {{ viewDate$ | async | date: formatShow }} <i *ngIf="titleTemplate === 'agenda'"
                                                      class="mdi mdi-chevron-down"></i>
      </div>

      <div class="DateSelectContainer_Inner" #overlay>
        <div class="DateSelectContainer_Nav">
          <button class="btn btn-clean" (click)="handlePrevMonth()"><i class="mdi mdi-chevron-left"></i> Mese precedente</button>
          <button class="btn btn-clean" (click)="handleNextMonth()">Mese seguente <i class="mdi mdi-chevron-right"></i> </button>
        </div>
        <div class="DateSelectContainer_Calendar" #calendarWrapper>

          <mwl-calendar-month-view (dayClicked)="handleDayClick($event)" [headerTemplate]=""
                                   [viewDate]="viewDate$ | async" [weekStartsOn]="1"
                                   [cellTemplate]="customCellTemplate"></mwl-calendar-month-view>

          <ng-template
            #customCellTemplate
            let-day="day"
            let-openDay="openDay"
            let-locale="locale"
            let-viewDate="viewDate"
            let-tooltipPlacement="tooltipPlacement"
            let-highlightDay="highlightDay"
            let-unhighlightDay="unhighlightDay"
            let-eventClicked="eventClicked"
            let-tooltipTemplate="tooltipTemplate"
            let-tooltipAppendToBody="tooltipAppendToBody"
            let-tooltipDelay="tooltipDelay"
            let-trackByEventId="trackByEventId"
            let-validateDrag="validateDrag"
          >
            <div
              class="cal-cell-top"
              [attr.aria-label]="
                { day: day, locale: locale } | calendarA11y : 'monthCell'
              "
            >
              <span aria-hidden="true">
                <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
                  day.badgeTotal
                  }}</span>
                <span class="cal-day-number" [class.selected]="isSelected(day.date)">
                  {{ day.date | calendarDate : 'monthViewDayNumber' : locale }}
                </span>
              </span>
            </div>
            <div class="cal-events" *ngIf="day.events.length > 0">
              <div
                class="cal-event"
                *ngFor="let event of day.events; trackBy: trackByEventId"
                [ngStyle]="{ backgroundColor: event.color?.primary }"
                [ngClass]="event?.cssClass"
                (mouseenter)="highlightDay.emit({ event: event })"
                (mouseleave)="unhighlightDay.emit({ event: event })"
                [mwlCalendarTooltip]="
                  event.title | calendarEventTitle : 'monthTooltip' : event
                "
                [tooltipPlacement]="tooltipPlacement"
                [tooltipEvent]="event"
                [tooltipTemplate]="tooltipTemplate"
                [tooltipAppendToBody]="tooltipAppendToBody"
                [tooltipDelay]="tooltipDelay"
                mwlDraggable
                [class.cal-draggable]="event.draggable"
                dragActiveClass="cal-drag-active"
                [dropData]="{ event: event, draggedFrom: day }"
                [dragAxis]="{ x: event.draggable, y: event.draggable }"
                [validateDrag]="validateDrag"
                [touchStartLongPress]="{ delay: 300, delta: 30 }"
                (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
                [attr.aria-hidden]="{} | calendarA11y : 'hideMonthCellEvents'"
              ></div>
            </div>
          </ng-template>

        </div>

        <div class="DateSelectContainer_Months">
          <div class="DateSelectContainer_MonthsYear">{{ this.currentYear }}</div>
          <ng-container *ngFor="let month of months">
            <div class="DateSelectContainer_MonthsYear" *ngIf="month.index === 1">{{ this.currentYear + 1 }}</div>
            <div class="DateSelectContainer_MonthsItem" (click)="handleMonthClick(month.number)"
                 [class.active]="month.number === this.currentMonthNumber">{{ month.name }}</div>
          </ng-container>
        </div>
      </div>

    </div>
  `
})

export class GoogleDatePickerComponent implements OnChanges, AfterViewInit {

  @Output() onDayClick: EventEmitter<Date> = new EventEmitter<Date>()
  @ViewChild('overlay', {static: true}) overlay;
  @ViewChild('toggler', {static: true}) toggler;
  @Input() date: string
  @Input() formatShow: string = 'dd MMMM yyyy';
  @Input() titleTemplate: 'datepicker' | 'agenda' = 'agenda';

  monthsName = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
  currentYear = 0;
  currentMonth = 0;
  showOverlay: boolean = false;
  @ViewChild('calendarWrapper', {static: true}) calendarWrapper;


  viewDate$: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  currentMonthNumber: string = '';
  months: any[] = [];

  constructor() {
    const today = addMonths(new Date(), -1);
    this.currentMonth = parseInt(formatDate(today, 'M', 'it'));
    this.currentYear = parseInt(formatDate(today, 'yyyy', 'it'))
    this.months = new Array(12)
      .fill(0)
      .map((value, index, array) => ({
        index: this.currentMonth + index > 12 ? this.currentMonth + index - 12 : this.currentMonth + index,
        name: this.monthsName[(this.currentMonth + index > 12 ? this.currentMonth + index - 12 : this.currentMonth + index) - 1],
        number: formatDate(addMonths(today, index), 'yyyy-M', 'it')
      }))
  }

  inputDateToSubject(inputDate): void {

    let viewDate;

    if (inputDate instanceof Date) {

      viewDate = inputDate
      this.viewDate$.next(inputDate);

    } else if (inputDate instanceof moment) {

      // @ts-ignore
      viewDate = inputDate.toDate()

    } else {

      viewDate = moment(inputDate, 'YYYY-MM-DD').toDate();

    }

    this.viewDate$.next(viewDate);

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes && changes.hasOwnProperty('date') && changes.date && changes.date.currentValue) {

      this.inputDateToSubject(changes.date.currentValue)

    }

  }

  subscriber$ = this.viewDate$.subscribe((date: Date) => {

    this.currentMonthNumber = formatDate(date, 'yyyy-M', 'it');

  })

  viewDate = new Date();

  ngAfterViewInit() {

    // https://github.com/maxmarinich/vanilla-swipe
    const mouseTrackingEnabled = !VanillaSwipe.isTouchEventsSupported();

    const VS = new VanillaSwipe({
      element: this.calendarWrapper.nativeElement,
      onSwiped: (e, swipe) => {
        if (Math.abs(swipe.deltaX) > Math.abs(swipe.deltaY)) {
          const date = this.viewDate$.value;
          if (swipe.deltaX < 0) {
            e.preventDefault();
            this.viewDate$.next(new Date(new Date(date).setMonth(date.getMonth() + 1)));
          } else {
            e.preventDefault();
            this.viewDate$.next(new Date(new Date(date).setMonth(date.getMonth() + -1)));
          }
        }

      },
      mouseTrackingEnabled,
    });

    VS.init();

    window.addEventListener('click', () => {

      this.showOverlay = false;
      this.inputDateToSubject(this.date)

    })

    this.overlay.nativeElement.addEventListener('click', (e) => {
      e.stopPropagation();
    })

    this.toggler.nativeElement.addEventListener('click', (e) => {
      e.stopPropagation();
    })

  }

  handleDayClick($event: { day: MonthViewDay<any>; sourceEvent: any }) {

    this.onDayClick.emit($event.day.date);
    this.viewDate$.next($event.day.date);
    console.log($event.day.date);
    this.showOverlay = false;

  }

  handleMonthClick(month) {

    const exploded = month.split('-');
    this.viewDate$.next(new Date(exploded[0], exploded[1] - 1, 1))

  }

  isSelected(date: Date): boolean {

    const day = formatDate(date, 'yyyy-MM-dd', 'it_IT');
    const selected = formatDate(this.viewDate$.value, 'yyyy-MM-dd', 'it_IT');
    return day === selected;

  }

  handlePrevMonth() {
    const date = this.viewDate$.value;
    this.viewDate$.next(new Date(new Date(date).setMonth(date.getMonth() - 1)));
  }

  handleNextMonth() {
    const date = this.viewDate$.value;
    this.viewDate$.next(new Date(new Date(date).setMonth(date.getMonth() + 1)));
  }


}
